<!-- <h2 class="font-style" mat-dialog-title>Alert</h2>

<mat-dialog-content>
	<h3 class="font-style">
		{{message}}
    </h3>
</mat-dialog-content>
<mat-dialog-actions style="justify-content:center !important">
    <button class="my-btn" type="submit" mat-dialog-close>Close</button>
</mat-dialog-actions> -->

<div style="margin-top: -7%;border: tomato;">

  <!-- <mat-dialog-actions style="justify-content:right !important"> -->
  <mat-dialog-actions style="justify-content:right !important">
    <!-- <button mat-raised-button class="buttons" mat-dialog-close>Close</button> -->
      <!-- <button class="my-btn" type="submit" mat-dialog-close>Close</button> -->
      <mat-icon mat-dialog-close class="close-button" routerLink={{homeUrl}}>
        close
    </mat-icon>
  </mat-dialog-actions>

  <mat-dialog-content style="text-align: center">

    <mat-icon class="mail-icon-style">
      mail
    </mat-icon>

    <h2 class="font-style">
      {{message1}}
    </h2>

    <h3 class="font-style">
      {{message2}}
    </h3>

  </mat-dialog-content>

  <!-- <mat-dialog-actions style="justify-content:center !important">
      <button class="my-btn" type="submit" mat-dialog-close>Close</button>
  </mat-dialog-actions> -->

</div>