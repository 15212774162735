<div class="container main-container animated fadeIn">

    <p class="contact-us-headingg global-heading pt-4">
        Contact Us
    </p>
    <p class="my-grey">
        We will be happy to answer your questions and listen your thoughts about us.
        Here's how you can reach us...
    </p>
    <!-- <div class="row border my-margin mb-5"> -->
    <div class="row container-fluid justify-content-center">
        <!-- <div class="col-12 col-md-4 p-4 theme-background"> -->
        <div class="col-12 col-md-5 theme-background">

            <img src="/assets/images/customer_support.webp" alt="Customer Support Image" class="support-img-style">

            <!-- <h3>
                We’d love to hear from you
            </h3>
            <p>
                Our employment screening experts are available to answer your questions and help you design background check packages, tailored to your company’s needs.
            </p> -->

        </div>
        <div class="border col-12 col-md-7 p-4">
            <!-- <p class="call-para">Call us at</p>
            <h3 class="mobile">+91 7710999684</h3>
            <p class="leave-para">or leave a message here</p> -->
            <div class="alert my-alert-danger alert-dismissible fade show sticky-top container bounceIn"
                [class.d-none]="unExpectedErrorAlert" role="alert">
                <strong>Unexpected Error, Couldn't Send Your Message</strong>
                <button type="button" class="close" (click)="unExpectedErrorAlert=true" data-dismiss="alert"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="alert my-alert-success alert-dismissible fade show sticky-top container bounceIn"
                [class.d-none]="mailSentAlert" role="alert">
                <strong>Your Message has been sent</strong>
                <button type="button" class="close" (click)="mailSentAlert=true" data-dismiss="alert"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- <form [formGroup]="contactUsForm" (ngSubmit)=contactUsFormSubmit()  action="https://formspree.io/f/mrgvvbea" method="POST">                 -->
            <form [formGroup]="contactUsForm" (ngSubmit)=contactUsFormSubmit()> 

                <!-- <mat-form-field class="form-responsive-width pr-2"> -->
                <mat-form-field class="form-responsive-width pr-2">
                    <input matInput placeholder="First Name" formControlName="firstName" required>
                    <mat-error *ngIf="firstName.touched && firstName.invalid && firstName.errors?.required">
                        First Name is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-responsive-width pr-2">
                    <input matInput placeholder="Last Name" formControlName="lastName" required>
                    <mat-error *ngIf="lastName.touched && lastName.invalid && lastName.errors?.required">
                        Last Name is required
                    </mat-error>
                </mat-form-field>

                <!-- <mat-form-field class="form-responsive-width pr-2"> -->
                <mat-form-field class="form-responsive-width pr-2">
                    <!-- <input matInput placeholder="Mobile No." formControlName="mobile" pattern="^([6-9][0-9]{9})$" required> -->
                    <input matInput placeholder="Mobile No." formControlName="mobile" pattern="\([0-9]{3}\)[0-9]{3}\-[0-9]{4}" (blur)="checkMobileNo()" required>
                    <mat-error *ngIf="mobile.touched && mobile.invalid && mobile?.errors?.required">
                        Mobile No. is required
                    </mat-error>
                    <mat-error *ngIf="mobile.touched && mobile.invalid && mobile?.errors?.pattern">
                        Invalid Mobile No.
                    </mat-error>
                    <mat-hint>
                        E.g: (000)000-0000
                    </mat-hint>
                </mat-form-field>

                <mat-form-field class="form-responsive-width pr-2">
                    <mat-label class="mat-style">Country *</mat-label>
                    <mat-select formControlName="country" class="mat-style">
                      <mat-option *ngFor="let con of countryList" [value]="con.countryName" class="mat-style"> 

                        <img src="{{con.countryFlag}}" alt="Country Image" class="country-dropdown-img-style">
                        {{con.countryName}} 
                        
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="country.touched && country.invalid && country.errors?.required">
                        Country is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-full-width">
                    <input matInput placeholder="Email" formControlName="email" required>
                    <mat-error *ngIf="email.touched && email.invalid && email.errors?.required">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="email.touched && email.invalid && email.errors?.email">
                        Please enter valid Email
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-full-width">
                    <input matInput placeholder="Company Name" formControlName="company" required>
                    <mat-error *ngIf="company.touched && company.invalid && company.errors?.required">
                        Company Name is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-full-width">
                    <textarea matInput class="text-area-style" placeholder="Message" formControlName="comment" required></textarea>
                    <mat-error *ngIf="comment.touched && comment.invalid && comment.errors?.required">
                        Message is required
                    </mat-error>
                </mat-form-field>

                <div class="d-flex justify-content-center">
                    <div>
                        <button class="my-btn" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="loading" class="display-1 text-center">
    <div class="my-overlay d-flex justify-content-center align-items-center">
        <div class="p-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>