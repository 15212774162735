<nav class="navbar navbar-expand-lg fixed-top navbar-shadoww navbar-custom " id="my-nav">
  <a class="navbar-brand pl-2" routerLink={{homeUrl}}>
    <!-- <span class="nav-link">Home</span> -->
    <!-- <img src="/assets/images/logo.jpg" class="navbar-brand-logo my-nav-logo img img-fluid" alt=""> -->
    <img src="/assets/images/RS_Logo.jpeg" class="navbar-brand-logo my-nav-logo img img-fluid" alt="">
  </a>
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse p-2 w-100">
    <ul class="navbar-nav ml-auto">
      <!-- <li *ngIf="!isLoggedin else userBlock" class="nav-item">
        <a class="nav-link" routerLink={{loginUrl}} (click)="isMenuCollapsed = true">Login </a>
      </li>
      <ng-template #userBlock>
        <li ngbDropdown  class="nav-item" style="cursor: pointer;">
          <a class="nav-link" id="dropdownBasic2" ngbDropdownToggle>{{userName | titlecase}}</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="drop-down-div">
            <li ngbDropdownItem class="nav-item">
              <a class="nav-link" routerLink={{profileUrl}} (click)="isMenuCollapsed = true">My Account</a>
            </li>
            <li ngbDropdownItem class="nav-item my-logout" >
              <a class="nav-link"  (click)="logoutUser()">Logout</a>
            </li>
          </div>
        </li>
        
      </ng-template>
      <li class="nav-item">
        <a class="nav-link" routerLink={{ordersUrl}} (click)="isMenuCollapsed = true">My Orders</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink={{homeUrl}}>Home</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink={{aboutUrl}} (click)="isMenuCollapsed = true">About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink={{contactUrl}} (click)="isMenuCollapsed = true">Contact Us</a>
      </li>
    </ul>
    <!-- <button mat-raised-button color="primary" (click)="onSetTheme('default-theme')">Default</button>
    <button mat-raised-button color="primary" (click)="onSetTheme('dark-theme')">Dark</button>
    <button mat-raised-button color="primary" (click)="onSetTheme('light-theme')">Light</button> -->
  </div>
</nav>




<app-loader [hidden]="!loading" [data]="{type: 'full'}"></app-loader>
<div [hidden]="loading">
  
    <!-- <div class="feedback">
        Feedback
    </div> -->

    <!-- <div [ngClass]="showFullSide ? 'side-container-full': 'side-container-half'" (click)="setSideBar()">
      <div class="option row">
        <mat-icon class="icon-style">
          record_voice_over_icon
        </mat-icon>
        <span class="icon-text" *ngIf="showFullSide">
          Chat Now
        </span>
      </div>
      <div class="option row ">
        <mat-icon class="icon-style">
          record_voice_over_icon
        </mat-icon>
        <span class="icon-text" *ngIf="showFullSide">
          836-637-1198
        </span>
      </div>
      <div class="option row">
        <mat-icon class="icon-style">
          record_voice_over_icon
        </mat-icon>
        <span class="icon-text" *ngIf="showFullSide">
          Shop Plans
        </span>
      </div>
    </div> -->

    <router-outlet></router-outlet>
</div>


<!-- Footer -->
<footer class="page-footer font-small blue my-footer pt-4">
  <div class="container-fluid ">
    <div class="row">
      <!-- <div class="col-sm-4 mt-md-0 mt-3 text-left">
        <p class="text-uppercase footer-para">whatsapp</p>
        <a href="https://api.whatsapp.com/send?phone=7710999684" class="footer-cta" target="_blank" rel="noopener noreferrer">
          <h5 class="footer-font d-flex footer-h5">
            <img src="/assets/images/whatsapp.png" style="height: 22px; width: 22px;" class="img img-fluid mr-2" alt="whatsapp">
            <svg-icon src="../assets/icons/whatsapp.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-right.px':10 }"></svg-icon>  
            Tap to send a message
          </h5>
          
        </a>
      </div> -->

      <!-- <div class="col-md-4 mb-md-0 mb-3 text-left margin-sm-top"> -->
      <div class="mm-2 col-12 col-md-4 mb-4 rs-text-center">
        <p class="text-uppercase footer-para">Email</p>
        <a href="mailto:support@rightscreening.com" class="footer-cta rs-text-center" target="_blank" rel="noopener noreferrer">
          <h5 class="footer-font d-flex footer-h5 rs-text-center">
            <span class="material-icons mr-2">
              email
            </span>
              support@rightscreening.com
          </h5>
        </a>
      </div>

      <!-- <div class="col-md-4 mb-md-0 mb-3 text-left margin-sm-top"> -->
      <div class="mm-2 col-12 col-md-4 mb-4 rs-text-center">
        <p class="text-uppercase footer-para">Connect with us</p>
        <div class="footer-icon-container">
          <div class="footer-icon-img">
            <a class="footer-cta" target="_blank" rel="noopener noreferrer">
              <!-- <h5 class="footer-font d-flex footer-h5"> -->
                <!-- <span class="material-icons">
                  facebook
                </span> -->
                <!-- <mat-icon class="iconStyle">facebook</mat-icon> -->
                <svg-icon src="../assets/icons/facebook.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-right.px':10 }"></svg-icon>
              <!-- </h5> -->
            </a>
          </div>
          <div class="footer-icon-img text-center">
            <a class="footer-cta" target="_blank" rel="noopener noreferrer">
              <!-- <h5 class="footer-font d-flex footer-h5"> -->
                <!-- <span class="material-icons">
                  instagram
                </span> -->
                <!-- <mat-icon class="iconStyle">instagram</mat-icon> -->
                <svg-icon src="../assets/icons/linkedin.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-left.px':20 }"></svg-icon>
              <!-- </h5> -->
            </a>
          </div>
          <div class="footer-icon-img">
            <a class="footer-cta" target="_blank" rel="noopener noreferrer">
              <!-- <h5 class="footer-font d-flex footer-h5"> -->
                <!-- <span class="material-icons">
                  twitter
                </span> -->
                <!-- <mat-icon class="iconStyle">twitter</mat-icon> -->
                <svg-icon src="../assets/icons/twitter.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-left.px':40 }"></svg-icon>
              <!-- </h5> -->
            </a>
          </div>
          <div class="footer-icon-img">
            <a class="footer-cta" target="_blank" rel="noopener noreferrer">
              <!-- <h5 class="footer-font d-flex footer-h5"> -->
                <!-- <span class="material-icons">
                  youtube
                </span> -->
                <!-- <mat-icon class="iconStyle">youtube</mat-icon> -->
                <svg-icon src="../assets/icons/youtube.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-left.px':60 }"></svg-icon>
              <!-- </h5> -->
            </a>
          </div>
          <div class="footer-icon-img">
            <a class="footer-cta" target="_blank" rel="noopener noreferrer">
              <!-- <h5 class="footer-font d-flex footer-h5"> -->
                <!-- <span class="material-icons">
                  linkedin
                </span> -->
                <!-- <mat-icon class="iconStyle">linkedin</mat-icon> -->
                <svg-icon src="../assets/icons/instagram.svg" [svgStyle]="{ 'fill': 'rgb(245,244,246)', 'margin-left.px':80  }"></svg-icon>
              <!-- </h5> -->
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-4 mb-md-0 mb-3 text-left margin-sm-top"> -->
      <div class="mm-2 col-12 col-md-4 mb-4 rs-text-center footer-copyright">
        © {{copyRightYear}} Right Screening
      </div>

    </div>
  </div>

  <!-- <div class="footer-copyright text-center py-3">© {{copyRightYear}} Right Screening</div> -->
</footer>
