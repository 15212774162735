<!-- ##################Banner##############  -->
<!-- <section id="banner">
    <div class="container-fluid home-banner animated fadeIn text-center">
        <img src="/assets/images/home_page_banner.webp" alt="online coconut water" class="img img-fluid banner-img ">
        <div class="d-flex justify-content-around banner-icon-container">
        </div>
    </div>
</section> -->


<section id="banner">
    <div class="container-fluid home-banner animated fadeIn text-center">
        <p class="banner-heading2"> Right Screening </p>
        <h1 class="banner-heading">
            Building A Better Background Check Company
        </h1>
        <img src="/assets/images/about_us_banner.webp" alt="Home Banner Image" class="img img-fluid banner-img">
        <div class="d-flex justify-content-around banner-icon-container">
            <div>
                <i class="material-icons banner-icons">
                    verified_user
                </i>
                <p class="icon-text">Reliable</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    grading
                </i>
                <p class="icon-text">Inspection</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    how_to_reg
                </i>
                <p class="icon-text">Trusted</p>
            </div>
        </div>
    </div>
</section>

<!-- <section id="subscription">
    <div class="container animated fadeIn mt-4 pt-4">
        <div class="text-center">
            <h2 class="global-heading">Background Check Services</h2>
        </div>
        <div class="row justify-content-center">
            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Criminal records checks</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Find out about a candidate’s criminal history with a comprehensive, easy-to-read report of their publicly available and legally reportable criminal records directly from the courts. National, federal, state, and county checks are available.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>
            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Education verification</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Verify candidates’ academic history to ensure your new hires have the skills necessary to be an asset to your growing team. We search public education databases to authenticate degrees earned in the US and in more than 200 countries.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>
            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Employment verification</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Build a trusted team by confirming the information provided by a candidate is true, and verify work history to ensure they have the experience required to fill your position. We make a manual, time-consuming process quick and easy.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>


            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Motor vehicle reports (MVR)</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidates’ driving records to identify qualified candidates with safe driving records. Our MVR reports confirm whether a candidate’s driver’s license is valid or has been suspended, and may reveal any driving-related violations.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>
            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Drug testing</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Support a safe workplace with an employment drug screening program. Our drug screening options are designed to meet your company policy and drug-free workplace obligations, while helping you maintain regulatory compliance.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>
            <div class="mm-2 col-6 col-md-3 mb-5 mr-5">
                <div class="card home-page-cards effect7">
                    <div class="text-center">
                        <h5 class="card-title m-4 pb-0">Civil search</h5>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review a candidate’s civil court records to learn about relevant non-criminal legal history, such as restraining orders, tax claims, interstate disputes, and more. County and federal civil court searches are available.
                        </p>
                        <button class="learn-button">Learn More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<!-- ##################Subscription##############  -->
<section id="subscription">
    <div class="container animated fadeIn mt-2 pt-2">
        <div class="text-center mb-4">
            <h2 class="global-heading">Background Check Services</h2>
        </div>
        <div class="row justify-content-center">

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        how_to_reg
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Criminal Records Check</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review criminal records from local, state, and federal databases along with information about arrests, convictions, and other criminal activities.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        school
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Education Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Provides individual's highest completed education verification by directly contacting schools, universities and online database.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        badge
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Employment Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Provides verification by contacting employers, work number to ensure applicant has experience listed on resume and verify work history.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        local_shipping
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Motor Vehicle Records</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Provides an individual's driving history, including traffic violations, accidents, and license suspensions and check whether it is valid or not.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        vaccines
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Drug & Health Screenings</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Provides access to our network of collection sites and clinics to perform drug testing and other work-related health screenings. 
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <!-- <mat-icon class="icon-style">
                        gavel
                    </mat-icon> -->
                    <mat-icon class="icon-style">
                        monetization_on
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Credit Check</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Provides report to go through individual's financial history, including credit card debts, credit limits, loans, bankruptcies, and collections.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <!-- <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        how_to_reg
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Criminal Records Checks</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidate’s criminal history with a comprehensive and easy-to-read report of their criminal records directly from courts.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        school
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Education Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Verify candidates’ academic history to ensure your new hires have the necessary skills to be an asset to your growing team.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        badge
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Employment Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Build a trusted team by confirming the information provided by a candidate is true, and verify work history.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        local_shipping
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Motor Vehicle Reports(MVR)</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidates’ driving records to identify qualified candidates and whether it is valid or has been suspended.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        vaccines
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Drug Testing</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Support a safe workplace with an employment drug screening program designed to meet your company policy. 
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        gavel
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Civil Search</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidate’s civil records to identify non-criminal history such as restraining orders, tax claims, interstate disputes.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div> -->

        </div>
    </div>
</section>


<!-- <div class="text-center">
    <h2 class="global-heading">Background Check Services</h2>
</div>

<mat-carousel
  timings="250ms ease-in"
  [autoplay]="false"
  interval="5000"
  color="accent"
  maxWidth="100%"
  proportion="25"
  slides="2"
  slideHeight="50vh"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
  lazyLoad="false"
  class="col-style"
>

    <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of slides; let i = index"
    overlayColor="#0000FFFF"
    [hideOverlay]="true"
    class="col-slide-style">

        <div *ngIf="slides[i].name=='slide1'" class="carousel-inner row no-gutters justify-content-center align-self-center mt-4 ml-5">

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        how_to_reg
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Criminal Records Checks</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidate’s criminal history with a comprehensive and easy-to-read report of their criminal records directly from the courts.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        school
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Education Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Verify candidates’ academic history to ensure your new hires have the necessary skills to be an asset to your growing team.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        badge
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Employment Verification</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Build a trusted team by confirming the information provided by a candidate is true, and verify work history.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

        </div>


        <div *ngIf="slides[i].name=='slide2'" class="carousel-inner row no-gutters justify-content-center align-self-center mt-4 ml-4">

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        local_shipping
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Motor Vehicle Reports(MVR)</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidates’ driving records to identify qualified candidates and whether it is valid or has been suspended.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        vaccines
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Drug Testing</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Support a safe workplace with an employment drug screening program designed to meet your company policy. 
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

            <div class="mm-2 col-12 col-md-4 mb-5">
                <div class="card home-page-cards effect7">
                    <mat-icon class="icon-style">
                        gavel
                    </mat-icon>
                    <div class="text-center">
                        <h4 class="card-title m-4 pt-4 pb-0">Civil Search</h4>
                        <p style="text-align: left;" class="card-textt my-card-text px-4">
                            Review candidate’s civil records to identify non-criminal history such as restraining orders, tax claims, interstate disputes.
                        </p>
                    </div>
                    <button class="learn-button">Learn More</button>
                </div>
            </div>

        </div>

    </mat-carousel-slide>
</mat-carousel> -->

<div class="my-overlay d-flex justify-content-center align-items-center" *ngIf="lazySpinner">
    <div class="p-2">
        <mat-spinner></mat-spinner>
    </div>
</div>