import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmailService } from 'src/app/services/email.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { LazyTwoService } from '../lazyTwoServices/lazy-two.service';
//import '../../assets/smtp.js'; //file path may change 
declare let Email: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  loading = false;
  unExpectedErrorAlert = true;
  mailSentAlert = true;
  countryList: any[]=[];

  constructor(
    //private lazyTwo: LazyTwoService
    private emailService: EmailService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) { }

  contactUsForm = new FormGroup({
    firstName : new FormControl('', [
      Validators.required
    ]),
    lastName : new FormControl('', [
      Validators.required
    ]),
    email : new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    mobile : new FormControl('', [
      Validators.required,
      Validators.minLength(13),
      Validators.maxLength(13)
    ]),
    company : new FormControl('', [
      Validators.required
    ]),
    country : new FormControl('', [
      Validators.required
    ]),
    comment : new FormControl('', Validators.required),
  });
  
  get firstName() {
    return this.contactUsForm.get('firstName');
  }
  get lastName() {
    return this.contactUsForm.get('lastName');
  }
  get email() {
    return this.contactUsForm.get('email');
  }
  get mobile() {
    return this.contactUsForm.get('mobile');
  }
  get company() {
    return this.contactUsForm.get('company');
  }
  get country() {
    return this.contactUsForm.get('country');
  }
  get comment() {
    return this.contactUsForm.get('comment');
  }

  contactUsFormSubmit() {
    
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      return;
    }

    //this.showAlert();

    // this.loading = true;
    // //this.lazyTwo.contactUSForm(this.contactUsForm.value).subscribe(
    //   data => {
    //     this.loading = false;
    //     this.mailSentAlert = false;
    //     this.showAlert();
    //     this.contactUsForm.reset();
    //   },
    //   (error: Response) => {
    //     this.loading = false;
    //     this.unExpectedErrorAlert = false;
    //   }
    // );

    let contactForm = { 
      subject: 'Right Screening Queries',
      Name: this.firstName.value+' '+this.lastName.value, 
      Email: this.email.value, 
      Mobile: this.mobile.value,
      Company: this.company.value, 
      Country: this.country.value, 
      Message: this.comment.value
    }

    this.loading = true;
    this.emailService.contactUSForm(contactForm).subscribe(
      data => {
        this.loading = false;
        //this.mailSentAlert = false;
        this.showAlert();
      },
      (error: Response) => {
        this.loading = false;
        this.unExpectedErrorAlert = false;
      }
    );
        

  }

  showAlert(){

    const dialogRef = this.dialog.open(AlertDialogComponent,{
      width: '360px',
      maxHeight: '90vh',
      disableClose: true,
      data:{
        message1: "THANK YOU",
        message2: "Your email has been sent",
      }
    });

  }

  checkMobileNo() {

    let mobileNo = this.mobile.value;
    var regex=/^[0-9]*$/;

    if(mobileNo?.length>=10 && regex.test(mobileNo)){
      console.log(regex.test(mobileNo))
      console.log(mobileNo)
      console.log(mobileNo.substring(0, 2))
      console.log(mobileNo.substring(3, 5))
      console.log(mobileNo.substring(6, 9))
      let mobileFormat = "("+mobileNo.substring(0, 3)+")"+mobileNo.substring(3, 6)+"-"+mobileNo.substring(6, 10);
      this.mobile.setValue(mobileFormat)
    }  

  }


  ngOnInit() {

    this.loading = true;

    this.countryList.push({countryName: "Australia", countryFlag: "/assets/country/Australia.jpg"});
    this.countryList.push({countryName: "Canada", countryFlag: "/assets/country/Canada.jpg"});
    this.countryList.push({countryName: "China", countryFlag: "/assets/country/China.jpg"});
    this.countryList.push({countryName: "France", countryFlag: "/assets/country/France.jpg"});
    this.countryList.push({countryName: "Germany", countryFlag: "/assets/country/Germany.jpg"});
    this.countryList.push({countryName: "India", countryFlag: "/assets/country/India.jpg"});
    this.countryList.push({countryName: "Malaysia", countryFlag: "/assets/country/Malaysia.jpg"});
    this.countryList.push({countryName: "Singapore", countryFlag: "/assets/country/Singapore.jpg"});
    this.countryList.push({countryName: "United Kingdom", countryFlag: "/assets/country/UK.jpg"});
    this.countryList.push({countryName: "United States", countryFlag: "/assets/country/US.jpg"});

    this.loading = false;
    
  }

}
