import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUrls } from '../apiUrls/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private apiUrls: ApiUrls
  ) { }

  contactUSFormOld(data) {
    console.log('from service', data);
    return this.http.post(this.apiUrls.contactUsDataUrl, data);
  }

  contactUSForm(input: any) {
      //const email = input;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      //return this.http.post('https://formspree.io/f/mrgvvbea', input);
      return this.http.post('https://formspree.io/f/xwkjjyjz', input);
      // { Name: email.firstName+' '+email.lastName, Email: email.email, Mobile: email.mobile,
      //   Company: email.company, Country: email.country, Message: email.comment, subject: 'Right Screening Queries' }
      //);
      // return this.http.post('https://formspree.io/f/mrgvvbea',
      //   { Name: email.firstName+' '+email.lastName, Email: email.email, Mobile: email.mobile,
      //     Company: email.company, Country: email.country, Message: email.comment, subject: 'Right Screening Queries' },
      //   { 'headers': headers }).subscribe(
      //     response => {
      //     }
      //   );
  }
      
  
}
