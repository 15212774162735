<!-- ##################Banner##############  -->
<!-- <section id="banner">
    <div class="container-fluid home-banner animated fadeIn text-center">
        <p class="banner-heading-para"> Background Verification Portal </p>
        <h1 class="banner-heading">
            Building A Better Background Check Company
        </h1>
        <img src="/assets/images/banner2.webp" alt="online coconut water" class="img img-fluid banner-img ">
        <p class="banner-list first-list">
            <b>Order Online</b>
        </p>
        <p class="banner-list">
            <b>Easy Subscription</b>
        </p>
        <p class="banner-list">
            <b>Lorem ipsum dolor sit.</b>
        </p>
        <div class="d-flex justify-content-around banner-icon-container">
            <div>
                <i class="material-icons banner-icons">
                    verified_user
                </i>
                <p class="icon-text">Reliable</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    local_drink
                </i>
                <p class="icon-text">Hygenic</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    how_to_reg
                </i>
                <p class="icon-text">Trusted</p>
            </div>
        </div>
    </div>
</section> -->


<!-- <section id="banner">
    <div class="container-fluid home-banner animated fadeIn text-center">
        <p class="banner-heading-para"> Background Verification Portal </p>
        <h1 class="banner-heading">
            Building A Better Background Check Company
        </h1>
        <img src="/assets/images/about_us_banner.webp" alt="online coconut water" class="img img-fluid banner-img ">
        <div class="d-flex justify-content-around banner-icon-container">
            <div>
                <i class="material-icons banner-icons">
                    verified_user
                </i>
                <p class="icon-text">Reliable</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    grading
                </i>
                <p class="icon-text">Inspection</p>
            </div>
            <div>
                <i class="material-icons banner-icons">
                    how_to_reg
                </i>
                <p class="icon-text">Trusted</p>
            </div>
        </div>
    </div>
</section> -->

<section id="banner">
    <div class="container-fluid home-banner animated fadeIn text-center">
        <img src="/assets/images/home_page_banner.webp" alt="About Banner Image" class="img img-fluid banner-img">
        <div class="d-flex justify-content-around banner-icon-container">
        </div>
    </div>
</section>


<div class="container-fluid pt-2 animated fadeIn">
    <p class="global-heading">
        Right Screening's History
    </p>
    <p class="global-heading2">
        How We Got Our Start
    </p>
</div>

<div class="container animated fadeIn">
    <!-- <p class="mt-4">
        In the early 2000’s, data was rapidly being digitized. Court records and other public documents were suddenly more accessible. 
        This presented a huge opportunity for the background check industry to speed up the process while making it more affordable. 
        But because it was dominated by older companies with outdated technology, clunky business practices, and an uneasy relationship with the digital realm, legacy providers were slow to take advantage of it.
    </p>
    <p>
        Meanwhile, in Silicon Valley, our entrepreneurs were running an innovative and driven technology team that had already built simple, straightforward, all-digital products to help 
        consumers access this newly digitized public records data. They spotted an opportunity to aggregate the data in ways that would help overstretched and under-served HR and Operations people in small businesses
         get background checks done quickly, affordably, and in a way that eased the burden of compliance. 
        They created Right Screening and built a self-service <span class="primary_span">background check</span> platform for businesses that’s simple to use. 
    </p>
    <p>
        Today at <span class="primary_span">Right Screening</span> we’re continuing our quest to take the work out of background checks and help employers speed up their decision flows. Built to be simple, easy to use, 
        and configurable, Right Screening’s intuitive, paperless workflow allows you to bring on new hires faster, with less hassle, and with more confidence than any other service.
    </p> -->

    <p class="mt-4">
        At Right Screening, we understand the importance of making informed decisions when it comes to hiring. We focus on providing comprehensive background services to our clients that helps them to minimize risk and 
        ensure that they are making best possible choice for their organizations.
    </p>
    <p>
        Our mission is to deliver accurate, reliable and timely background check results that enable our clients to make informed decisions with confidence. We believe in uploading the highest ethical standard and 
        providing exceptional customer services, and we are committed to working closely with our clients to meet their needs and exceed their expectations.
    </p>
    <p>
        With over 15 years of experience in background check industry, our team of expert researchers and analysts is dedicated to deliver fast, accurate and comprehensive background check reports. We use the latest 
        technology and industry practices to ensure that our client have the information they need to make informed decisions.
    </p>
    <p>
        At <span class="primary_span">RightScreening</span>, we specialize in a range of <span class="primary_span">background check services</span> inculding criminal records, drug and health screenings, employment verifications and more.
    </p>
    <p>
        Contact us today to learn more about our background check services, we are here to help you.
    </p>


    <section id="subscription">
        <div class="container animated fadeIn mt-2 pt-2">
            <div class="text-center">
                <h2 class="global-heading4">Company Values</h2>
            </div>
            <div class="row justify-content-center">

                <div class="mm-2 col-12 col-md-4 mb-5 hide-border">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            record_voice_over_icon
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Customer Experience</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We keep needs and interests of our customers first and focus on delivering exceptional services that meets everyone's demands.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mm-2 col-12 col-md-4 mb-5 hide-border">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            lens_blur
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Quality</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                Success comes with quality and we focus on pursing excellence in all areas of our work from product enhancement to customer service and support.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mm-2 col-12 col-md-4 mb-5 hide-border">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            ac_unit
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Integrity</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We believe in doing things in right way with honesty, transparency and ethics in all aspects or business, we don’t compromise our integrity.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="mm-2 col-12 col-md-4 mb-5 hide-border">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            record_voice_over_icon
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Customer Delight</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We deliver awesome customer experiences that exceed expectations meeting everyone's demands. 
                            </p>
                        </div>
                    </div>
                </div>
    
                <div class="mm-2 col-12 col-md-4 mb-5">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            ac_unit
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Integrity</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We don’t compromise our integrity. We do things the right way and don’t come close to crossing the line.
                            </p>
                        </div>
                    </div>
                </div>
    
                <div class="mm-2 col-12 col-md-4 mb-5">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            lens_blur
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Innovation</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We always look for ways to improve every aspect of our business and organization. We are never satisfied with the status quo.  
                            </p>
                        </div>
                    </div>
                </div> -->
    
                <!-- <div class="mm-2 col-12 col-md-4 mb-5">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            speed
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Speed With Rigor</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We blend relentless velocity with data-driven analysis, attention to detail, and an emphasis on quality.    
                            </p>
                        </div>
                    </div>
                </div>
    
                <div class="mm-2 col-12 col-md-4 mb-5">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            handshake
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Transparency</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We communicate openly, honestly, and directly. We celebrate good news, but also confront bad news.    
                            </p>
                        </div>
                    </div>
                </div>
    
                <div class="mm-2 col-12 col-md-4 mb-5">
                    <div class="card home-page-cards effect7">
                        <mat-icon class="icon-style">
                            person_pin
                        </mat-icon>
                        <div class="text-center">
                            <h4 class="card-title m-3 pt-4 pb-0">Leadership & Accountability</h4>
                            <p style="text-align: left;" class="card-textt my-card-text px-4">
                                We lead by example, regardless of role, and are highly accountable to the commitments we make.
                            </p>
                        </div>
                    </div>
                </div> -->
                
            </div>
        </div>
    </section>

</div>