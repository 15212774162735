<!-- Unexpected alert Alert -->
<div class="alert my-alert-danger alert-dismissible fade show sticky-top container bounceIn"
  [class.d-none]="unExpectedErrorAlert" role="alert">
  <strong>Unexpected Error</strong>
  <button type="button" class="close" (click)="unExpectedErrorAlert=true" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- create child component for each screens for better code managment -->

<div class="p-4 pt-5 mt-4 container-fluid">
  <!-- ############login############## -->
  <div class="d-flex justify-content-center animated bounceInDown " *ngIf="loginUI">
    <div class="card my-card effect7">
      <h5 class="card-header my-card-header ">Login</h5>
      <div class="card-body">

        <form [formGroup]="loginForm" (ngSubmit)="loginData()" class="login-form">
          <div class="alert my-alert-danger alert-dismissible fade show sticky-top bounceIn"
            *ngIf="loginForm?.errors?.inValidLogin" role="alert">
            <strong>Wrong Credentials</strong>
            <button type="button" class="close" (click)="loginForm.errors.inValidLogin=false" data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <mat-form-field class="form-full-width py-2" floatLabel="auto">
            <input matInput placeholder="Mobile" type="tel" required formControlName="mobile"
              pattern="^([6-9][0-9]{9})$">
            <span matPrefix>+91&nbsp;</span>
            <mat-error *ngIf="mobile.touched && mobile.invalid && mobile?.errors?.required">
              mobile number is required
            </mat-error>
            <mat-error *ngIf="mobile.touched && mobile.invalid && mobile.errors?.pattern">
              Invalid Mobile
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-full-width py-2">
            <input matInput placeholder="Password" [type]="hidePassword ? 'password' : 'text'" required
              formControlName="password">
            <span matSuffix class="material-icons" (click)="hidePassword = !hidePassword">
              {{ hidePassword ? 'visibility' : 'visibility_off'}}
            </span>
            <mat-error *ngIf="password.touched && password.invalid && password?.errors?.required">
              Password is required
            </mat-error>
            <mat-error *ngIf="password.touched && password.invalid && password?.errors?.minlength">
              Password should be atleast {{password?.errors?.minlength?.requiredLength}} characters
            </mat-error>
            <mat-error *ngIf="mobile.touched && mobile.invalid && mobile.errors.connotContainSpace">
              Passowrd can not contain space
            </mat-error>

          </mat-form-field>

          <div class="text-center">
            <div class="d-flex align-items-center flex-column">
              <div>
                <mat-spinner *ngIf="loginWaitSpinner" diameter="40"></mat-spinner>
              </div>
              <div>
                <button type="submit" class="my-login-button" mat-raised-button color="primary"
                  [disabled]="disableLoginButton">
                  Login
                </button>
              </div>
            </div>
            <div>
              <button mat-button class="my-login-button mt-4 " (click)="registerUi()" mat-raised-button color="primary">
                New User?
              </button>
            </div>
            <div class="py-2">
              <button mat-button color="primary" (click)="forgotPasswordUi()">
                Forgot Password?
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- ############Register############## -->
  <div class="d-flex justify-content-center animated bounceInDown" *ngIf="registerUI">
    <div class="card my-card effect7">
      <h5 class="card-header my-card-header">Register</h5>
      <div class="card-body">
        <form (ngSubmit)="userRegister()" [formGroup]="userRegisterForm">
          <div class="alert my-alert-danger alert-dismissible fade show sticky-top bounceIn"
            [class.d-none]="duplicateRegistrationAlert" role="alert">
            <strong>Email or Mobile is already Registerd</strong>
            <button type="button" class="close" (click)="duplicateRegistrationAlert=true" data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <mat-form-field class="form-full-width py-22">
            <input matInput placeholder="Full Name" type="text" required formControlName="name">
            <mat-error *ngIf="regName.touched && regName.invalid && regName.errors.required">
              Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-full-width py-22" floatLabel="auto">
            <input matInput placeholder="Mobile" type="tel" required formControlName="mobile"
              pattern="^([6-9][0-9]{9})$">
            <span matPrefix>+91&nbsp;</span>
            <mat-error *ngIf="regMobile.touched && regMobile.invalid && regMobile?.errors?.required">
              mobile number is required
            </mat-error>
            <mat-error *ngIf="regMobile.touched && regMobile.invalid && regMobile?.errors?.pattern">
              Invalid Mobile
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-full-width py-22" floatLabel="auto">
            <input matInput placeholder="email" type="email" required formControlName="email">
            <mat-error *ngIf="regEmail.touched && regEmail.invalid && regEmail?.errors?.required">
              Email is required
            </mat-error>
            <mat-error *ngIf="regEmail.touched && regEmail.invalid && regEmail?.errors?.email">
              Please enter valid Email
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-full-width py-22">
            <input matInput placeholder="Password" [type]="hidePassword ? 'password' : 'text'" required
              formControlName="password">
            <mat-error *ngIf="regPassword.touched && regPassword.invalid && regPassword.errors.required">
              Password is required
            </mat-error>
            <mat-error *ngIf="regPassword.touched && regPassword.invalid && regPassword.errors.minlength">
              Password should be atleast {{regPassword.errors.minlength.requiredLength}} characters
            </mat-error>
            <mat-error *ngIf="regPassword.touched && regPassword.invalid && regPassword.errors.connotContainSpace">
              Passowrd can not contain spaces
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-full-width py-22">
            <input matInput placeholder="Confirm Password" [type]="hidePassword ? 'password' : 'text'" required
              formControlName="confirmPassword">
            <span matSuffix class="material-icons" (click)="hidePassword = !hidePassword">
              {{ hidePassword ? 'visibility' : 'visibility_off'}}
            </span>
            <mat-error
              *ngIf="regConfirmPassword.touched && regConfirmPassword.invalid && regConfirmPassword.errors.required">
              Password is required
            </mat-error>
            <mat-error
              *ngIf="(regConfirmPassword?.touched || regConfirmPassword?.dirty)&&(regConfirmPassword?.value!==regPassword?.value)">
              Passowrd did not match
            </mat-error>
            <mat-error
              *ngIf="regConfirmPassword.touched && regConfirmPassword.invalid && regConfirmPassword.errors.minlength">
              Password should be atleast {{regConfirmPassword.errors.minlength.requiredLength}} characters
            </mat-error>
          </mat-form-field>

          <div class="text-center">
            <div>
              <div class="alert my-alert-danger alert-dismissible fade show sticky-top bounceIn"
                [class.d-none]="passwordMissMatchAlert" role="alert">
                <strong>Password did not match</strong>
                <button type="button" class="close" (click)="passwordMissMatchAlert=true" data-dismiss="alert"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <button type="submit" class="my-login-button" mat-raised-button color="primary"
                [disabled]="disableRegisterButton">
                Register
              </button>
            </div>
            <div>
              <button mat-button class="my-login-buttonn py-22 mt-4" (click)="loginUi()" color="primary">
                Already a User?
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  <!-- ###################Forgot Password UI######################## -->
  <div class="d-flex justify-content-center animated bounceInDown" *ngIf="forgotPasswordUI">
    <div class="card my-card effect7 forgot-password-card">
      <h5 class="card-header my-card-header">Forgot Password</h5>
      <div class="card-body">
        <form (ngSubmit)="forgotPassword()" [formGroup]="forgotPasswordForm" class="login-form">
          <div class="alert my-alert-success alert-dismissible fade show sticky-top bounceIn"
            [class.d-none]="emailSentAlert" role="alert">
            <strong>Email Sent, Please check your Email</strong>
            <button type="button" class="close" (click)="emailSentAlert=true" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="alert my-alert-danger alert-dismissible fade show sticky-top bounceIn"
            [class.d-none]="emailDoesnotExistAlert" role="alert">
            <strong>Email Does not exist</strong>
            <button type="button" class="close" (click)="emailDoesnotExistAlert=true" data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <mat-form-field class="form-full-width py-2" [class.d-none]="!emailSentAlert" floatLabel="auto">
            <input matInput placeholder="Email" type="email" required formControlName="email">
            <mat-error *ngIf="forgotEmail.touched && forgotEmail.invalid && forgotEmail.errors?.required">
              Email is required
            </mat-error>
            <mat-error *ngIf="forgotEmail.touched && forgotEmail.invalid && forgotEmail.errors?.email">
              Please enter valid E-mail
            </mat-error>
          </mat-form-field>

          <div class="d-flex align-items-center flex-column">
            <div class="text-center">
              <mat-spinner *ngIf="forgotWaitSpinner" diameter="40"></mat-spinner>
            </div>
            <div>
              <div class="text-center">
                <button type="submit" class="my-login-button" [class.d-none]="!emailSentAlert" mat-raised-button
                  color="primary" [disabled]="disableForgotButton">
                  Submit
                </button>
              </div>
              <div>
                <button mat-button class="my-login-buttonn py-22 mt-4" (click)="loginUi()" color="primary">
                  Already a User?
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>