import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { completeUrl } from '../angularUrl/angularUrl';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  message1: string
  message2: string
  cancelButtonText: string
  homeUrl: string

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertDialogComponent>) {
      if(data){
    this.message1 = data.message1 || this.message1;
    this.message2 = data.message2 || this.message2;
    // if (data.buttonText) {
    //   this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    // }
    }
  }

  ngOnInit(): void {
    this.homeUrl = completeUrl.home;
  }

}
